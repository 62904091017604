import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
    cef: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.SALE_AND_PURCHASE.GET_ALL:
            return {
                ...state,
                all: action.payload.sales_and_purchases
            };
        case ACTIONS.SALE_AND_PURCHASE.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.SALE_AND_PURCHASE.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(sale_and_purchase => sale_and_purchase.id_sale_and_purchase != action.payload.sales_and_purchase.id_sale_and_purchase),
                    action.payload.sales_and_purchase
                ]
            };
        case ACTIONS.SALE_AND_PURCHASE.DELETE:
            return {
                ...state,
                all: state.all.filter(sale_and_purchase => sale_and_purchase.id_sale_and_purchase != action.payload.id)
            };
        case ACTIONS.SALE_AND_PURCHASE.GET:
            return {
                ...state,
                current: action.payload.sales_and_purchase
            };
        case ACTIONS.SALE_AND_PURCHASE.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.SALE_AND_PURCHASE.CEF:
            return {
                ...state,
                cef: action.payload
            };
        case ACTIONS.ERROR:
            alert(action.payload.data);
            return state;
        default:
            return state;
    }
}