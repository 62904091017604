import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    all: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.DEPARTMENT.GET_ALL:
            return {
                ...state,
                all: action.payload
            };
        case ACTIONS.DEPARTMENT.SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case ACTIONS.DEPARTMENT.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}