import { SORT2_SUCCESS, SORT2_ERROR } from '../actions/sort2';

const initialState = {
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === SORT2_SUCCESS) {
        return {
            ...state,
            data: action.data,
            error: null
        };
    } else if (action.type === SORT2_ERROR) {
        return {
            ...state,
            error: action.error
        };
    }
    return state;
}