import { ACTIONS } from '../actions/_actions_types';
import { MEASUREMENT_ERROR, MEASUREMENT_SUCCESS } from "../actions/measurement";

let initialState = {
    all: {},
    current: {},
    searched: {},
    last_number: 0,
    all_stems: {},
    all_piles: {},
    volume: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case MEASUREMENT_SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case MEASUREMENT_ERROR:
            alert(action.error)
            return {
                ...state,
                error: action.error
            };
        case ACTIONS.MEASUREMENTS.GET_LAST_NUMBER:
            return {
                ...state,
                last_number: action.payload
            };
        case ACTIONS.MEASUREMENTS.GET_ALL_STEMS:
            return {
                ...state,
                all_stems: action.payload,
                searched: [],
            };
        case ACTIONS.MEASUREMENTS.GET_ALL_PILES:
            return {
                ...state,
                all_piles: action.payload,
                searched: [],
            };
        case ACTIONS.MEASUREMENTS.GET_VOLUME:
            return {
                ...state,
                volume: action.payload
            };
        case ACTIONS.MEASUREMENTS.GET_ALL:
            return {
                ...state,
                all: action.payload.measurements,
                searched: [],
            };
        case ACTIONS.MEASUREMENTS.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.MEASUREMENTS.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(measurement => measurement.id_measurement != action.payload.measurement.id_measurement),
                    action.payload.measurement
                ]
            };
        case ACTIONS.MEASUREMENTS.DELETE:
            return {
                ...state,
                all: state.all.filter(measurement => measurement.id_measurement != action.payload.id)
            };
        case ACTIONS.MEASUREMENTS.GET:
            return {
                ...state,
                current: action.payload.measurement
            };
        case ACTIONS.MEASUREMENTS.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.ERROR:
            alert(action.payload.data);
            return state;
        default:
            return state;
    }
}
