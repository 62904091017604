import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';

export const MOTIVE_SUCCESS = 'MOTIVE_SUCCESS';
export const fetchSuccess = data => ({
    type: MOTIVE_SUCCESS,
    data
});

export const MOTIVE_ERROR = 'MOTIVE_ERROR';
export const fetchError = error => ({
    type: MOTIVE_ERROR,
    error
});

export const postMotive = (motive) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/motives`, motive)
        .then(({motive}) => dispatch(fetchSuccess(motive)));
};

export const getMotives = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/motives/`)
        .then(({motives}) => dispatch(fetchSuccess(motives)));
};

export const getMotive = (id_motive) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/motives/${id_motive}`)
        .then(({motive}) => dispatch(fetchSuccess(motive)));
};

export const putMotive = (motive) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/motives/${motive.id_motive}`, motive)
        .then(({motive}) => dispatch(fetchSuccess(motive)));
};

export const deleteMotive = (id_motive) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/motives/${id_motive}`)
        .then(({motive}) => dispatch(fetchSuccess(motive)));
};