import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.HAMMERING_STEMS.GET_ALL:
            let newState = {
                ...state,
                all: action.payload
            };
            return newState;
        case ACTIONS.HAMMERING_STEMS.CLEAR:
            let clearedState = {
                ...state,
                all: {}
            };
            return clearedState;
        case ACTIONS.HAMMERING_STEMS.SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case ACTIONS.HAMMERING_STEMS.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}