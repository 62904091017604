import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';

export const REMARK_SUCCESS = 'REMARK_SUCCESS';
export const fetchSuccess = data => ({
    type: REMARK_SUCCESS,
    data
});

export const REMARK_ERROR = 'REMARK_ERROR';
export const fetchError = error => ({
    type: REMARK_ERROR,
    error
});

export const postRemark = (remark) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/remarks`, remark)
    .then(({remark}) => dispatch(fetchSuccess(remark)));
};

export const getRemarks = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/remarks/`)
        .then(({remarks}) => dispatch(fetchSuccess(remarks)));
};

export const getRemark = (id_remark) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/remarks/${id_remark}`)
        .then(({remark}) => dispatch(fetchSuccess(remark)));
};

export const putRemark = (remark) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/remarks/${remark.id_remark}`, remark)
        .then(({remark}) => dispatch(fetchSuccess(remark)));
};

export const deleteRemark = (id_remark) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/remarks/${id_remark}`)
        .then(({remark}) => dispatch(fetchSuccess(remark)));
};