import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';
import {fetchSuccess as fetchRoleSuccess} from './role';
import {fetchSuccess as fetchUserCompanySuccess} from './user_company';
import {axiosDelete, axiosGet, axiosPost, axiosPut} from "../axios";
import {ACTIONS} from "./_actions_types";
import {displaySuccessToast} from "./toast";


export const USER_SUCCESS = 'USER_SUCCESS';
export const fetchSuccess = data => ({
    type: USER_SUCCESS,
    data
});

export const USER_ERROR = 'USER_ERROR';
export const fetchError = error => ({
    type: USER_ERROR,
    error
});

export const registerUser = (app_user) => (dispatch) => {
    return axiosPost(`${API_BASE_URL}/users`, app_user).then((res) => {
        dispatch({
            type: ACTIONS.USERS.POST,
            payload: res.data
        })
        displaySuccessToast('Utilisateur créer avec succès');
    }, err => {
        alert(err.message)
    });
};


export const getUsers = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/users/`).then((res) => {
        dispatch({
            type: ACTIONS.USERS.GET_ALL,
            payload: res.data
        })
    });
};

export const getAllUsers = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/allusers`).then((res) => {
        dispatch({
            type: ACTIONS.USERS.GET_ALL,
            payload: res.data
        })
    });
};


export const deleteUser = (id_user) => (dispatch) => {

    if (localStorage.getItem("user_id") == id_user) {
        alert("You cannot delete your current user")
        return axiosGet(`${API_BASE_URL}/users`)
            .then((res) => {
                dispatch({
                    type: ACTIONS.USERS.GET_ALL,
                    payload: res.data
                })
            })
    }

    return axiosDelete(`${API_BASE_URL}/users/${id_user}`)
        .then(() => {
            dispatch({
                type: ACTIONS.USERS.DELETE
            })
            displaySuccessToast('Utilisateur supprimé avec succès');
        })
};


export const getUser = (id_user) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/users/${id_user}`)
        .then((users) => dispatch({
            type: ACTIONS.USERS.GET,
            payload: users
        }));
};

export const putUser = (app_user) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/users/${app_user.id_user}`, app_user)
        .then((users) => {
            dispatch({
                type: ACTIONS.USERS.PUT,
                payload: users
            });
            displaySuccessToast('Utilisateur modifié avec succès');
        });
};

export const changePassword = (pwd, id_user) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/users/pwd/${id_user}`, {password: pwd}).then(() => {
    });
};


// Roles
export const getUserRoles = (id_user) => (dispatch, getState) => {

    axiosGet(`${API_BASE_URL}/users/${id_user}/roles`).then((res) => {
        dispatch(fetchRoleSuccess(res.data))
    })
    // return get(dispatch, getState, `${API_BASE_URL}/users/${id_user}/roles`)
    //     .then(({roles}) => dispatch(fetchRoleSuccess(roles)));
};

export const putUserRole = (id_user, id_role, role) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/users/${id_user}/roles/${id_role}`, role)
        .then(({roles}) => dispatch(fetchRoleSuccess(roles)));
};

export const deleteUserRole = (id_user, id_role) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/users/${id_user}/roles/${id_role}`)
        .then(({roles}) => dispatch(fetchRoleSuccess(roles)));
};

// Companies
export const getUserCompanies = (id_user) => (dispatch, getState) => {

    axiosGet(`${API_BASE_URL}/users/${id_user}/companies`).then((res) => {
        dispatch({
            type: 'USER_COMPANY_SUCCESS',
            payload: res.data
        })
    }, error => {
    })
};

export const askCompany = (id_user, id_company) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/users/${id_user}/companies/${id_company}`,)
        .then(({company}) => dispatch(fetchUserCompanySuccess(company)));
};

export const removeCompany = (id_user, id_company) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/users/${id_user}/companies/${id_company}`)
        .then(({company}) => dispatch(fetchUserCompanySuccess(company)));
};

export const searchUsers = (data) => (dispatch) => {
    dispatch({
        type: ACTIONS.USERS.SEARCH,
        payload: {data}
    })

};