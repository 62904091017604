import { MEASUREMENT_PILE_SUCCESS, MEASUREMENT_PILE_ERROR } from '../actions/measurement_pile';
import { ACTIONS } from '../actions/_actions_types';

const initialState = {
    data: '',
    error: null,
    samples: [],
    assortment: null,
    map_all: []
};

export default function reducer(state = initialState, action) {
    if (action.type === MEASUREMENT_PILE_SUCCESS) {
        return {
            ...state,
            data: action.data,
            error: null
        };
    } else if (action.type === MEASUREMENT_PILE_ERROR) {
        alert(action.error)
        return {
            ...state,
            error: action.error
        };
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.GET_MEASUREMENT_PILE_SAMPLES) {
        return {
            ...state,
            samples: action.payload
        };
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.POST_MEASUREMENT_PILE_SAMPLES) {
        return {
            ...state,
            samples: [...state.samples, action.payload]
        }
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.DELETE_MEASUREMENT_PILE_SAMPLES) {
        return {
            ...state,
            samples: state.samples.filter(measurement_pile_sample => measurement_pile_sample.id_measurement_pile_sample != action.payload.id)
        };
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.PUT_MEASUREMENT_PILE_SAMPLES) {
        return {
            ...state,
            samples: [
                ...state.samples.filter(measurement_pile_sample => measurement_pile_sample.id_measurement_pile_sample != action.payload.id),
                action.payload
            ]
        };
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.FIND_MEASUREMENT_ASSORTMENT) {
        return {
            ...state,
            assortment: action.payload
        }
    } else if (action.type === ACTIONS.MEASUREMENT_PILES.MAP_PILES) {
        return { ...state, map_all: action.payload }
    }
    return state;
}