// import {CUT_SUCCESS, CUT_ERROR} from '../actions/cut';
import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: [],
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.CUT.GET_ALL:
            return {
                ...state,
                all: action.payload,
                searched: []
            };
        case ACTIONS.CUT.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.CUT.GET:
            return {
                ...state,
                all: state.all.map(cut => {
                    if (cut.id_cut === action.payload.id_cut) {
                        return {
                            ...cut,
                            ...action.payload
                        };
                    }
                    return cut;
                })
            };
        case ACTIONS.CUT.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.CUT.PUT:
            return { ...state, all: [...state.all.filter(cut => cut.id_cut !== action.payload.cut.id_cut), action.payload.cut] };
    }
    // if (action.type === CUT_SUCCESS) {
    //     return {
    //                ...state,
    //         data: action.data,
    //         error: null
    //     };
    // } else if (action.type === CUT_ERROR) {
    //     return {
    //        ...state,
    //         error: action.error
    //     };
    // }
    return state;
}
