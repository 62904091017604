export const ROLES = {
		//admin
		1:
			{
					coupe: "CRUD",
					martelage: "CRUD",
					cubage: "CRUD",
					cubage_tige_pile: "CRUD",
					depot: "CRUD",
					lot: "CRUD",
					partenaires: "CRUD",
					vente: "CRUD",
					utilisateurs: "CRUD",
					entreprises: "CRUD",
					mon_compte: "RU",   //utile ?
					categorie: "CRUD",
					certificats: "CRUD",
					deduction_type: "CRUD",
					departement: "CRUD",
					essence: "CRUD",
					type_de_foret: "CRUD",
					martelage_plant: "CRUD",
					type_intervention: "CRUD",
					tarif: "CRUD",
					qualite: "CRUD",
					remarque: "CRUD",
					role: "CRUD",
					tri: "CRUD",
					type_de_bois: "CRUD",
					assortiment: "CRUD",
					produit: "CRUD",
					type_de_produit: "CRUD",
					actions: "CRUD",
					conditions_generales: "CRUD",
					//only client
					carte: "R",
					//Management tokens
					partage: "CRUD"
			},
		//garde forestier
		2:
			{
					coupe: "CRUD",
					martelage: "CRUD",
					cubage: "CRUD",
					cubage_tige_pile: "CRUD",
					depot: "CRUD",
					lot: "CRUD",
					partenaires: "CRUD",
					vente: "CRUD",
					utilisateurs: "CRUD",
					entreprises: "RU",
					mon_compte: "RU",
					categorie: "R",
					certificats: "R",
					deduction_type: "R",
					departement: "R",
					essence: "R",
					type_de_foret: "R",
					martelage_plant: "CRUD",
					type_intervention: "R",
					tarif: "R",
					qualite: "R",
					remarque: "R",
					role: "R",
					tri: "R",
					type_de_bois: "R",
					assortiment: "R",
					produit: "CRUD",
					type_de_produit: "R",
					actions: "R",
					//only client
					carte: "R",
					partage: ""
				},
		//testeur
		3:
			{
					coupe: "CRUD",
					martelage: "CRUD",
					cubage: "CRUD",
					cubage_tige_pile: "CRUD",
					depot: "CRUD",
					lot: "CRUD",
					partenaires: "CRUD",
					vente: "CRUD",
					utilisateurs: "CRU",
					entreprises: "R",
					mon_compte: "RU",
					categorie: "R",
					certificats: "R",
					deduction_type: "R",
					departement: "R",
					essence: "R",
					type_de_foret: "R",
					martelage_plant: "CRUD",
					type_intervention: "R",
					tarif: "R",
					qualite: "R",
					remarque: "R",
					role: "R",
					tri: "R",
					type_de_bois: "R",
					assortiment: "R",
					produit: "CRUD",
					type_de_produit: "R",
					actions: "R",
					//only client
					carte: "R",
					partage: "CRUD"
			},
		//municipal
		4:
			{
					coupe: "R",
					martelage: "R",
					cubage: "R",
					cubage_tige_pile: "",
					depot: "",
					lot: "R",
					partenaires: "R",
					vente: "R",
					utilisateurs: "",
					entreprises: "R",
					mon_compte: "RU",
					categorie: "R",
					certificats: "R",
					deduction_type: "",
					departement: "R",
					essence: "R",
					type_de_foret: "R",
					martelage_plant: "R",
					type_intervention: "R",
					tarif: "R",
					qualite: "",
					remarque: "",
					role: "",
					tri: "",
					type_de_bois: "",
					assortiment: "",
					produit: "R",
					type_de_produit: "R",
					actions: "",
					//only client
					carte: "",
					partage: ""
			},
		//inspecteur cantonal
		5:
			{
					coupe: "R",
					martelage: "R",
					cubage: "R",
					cubage_tige_pile: "",
					depot: "",
					lot: "",
					partenaires: "",
					vente: "",
					utilisateurs: "",
					entreprises: "",
					mon_compte: "RU",
					categorie: "R",
					certificats: "",
					deduction_type: "",
					departement: "R",
					essence: "R",
					type_de_foret: "R",
					martelage_plant: "R",
					type_intervention: "R",
					tarif: "R",
					qualite: "",
					remarque: "",
					role: "",
					tri: "",
					type_de_bois: "",
					assortiment: "",
					produit: "R",
					type_de_produit: "R",
					actions: "",
					//only client
					carte: "",
					partage: ""

			},
		//contremaitre
		6:
			{
					coupe: "CRU",
					martelage: "CRU",
					cubage: "CRU",
					cubage_tige_pile: "CRUD",
					depot: "",
					lot: "R",
					partenaires: "",
					vente: "",
					utilisateurs: "",
					entreprises: "",
					mon_compte: "RU",
					categorie: "R",
					certificats: "",
					deduction_type: "R",
					departement: "R",
					essence: "R",
					type_de_foret: "R",
					martelage_plant: "CRUD",
					type_intervention: "R",
					tarif: "R",
					qualite: "R",
					remarque: "R",
					role: "",
					tri: "R",
					type_de_bois: "R",
					assortiment: "R",
					produit: "CRU",
					type_de_produit: "R",
					actions: "",
					//only client
					carte: "",
					partage: ""
			},
		//secretaire
		7:
			{
					coupe: "",
					martelage: "",
					cubage: "",
					cubage_tige_pile: "",
					depot: "",
					lot: "",
					partenaires: "",
					vente: "CRUD",
					utilisateurs: "",
					entreprises: "",
					mon_compte: "RU",
					categorie: "",
					certificats: "",
					deduction_type: "",
					departement: "",
					essence: "",
					type_de_foret: "",
					martelage_plant: "",
					type_intervention: "",
					tarif: "",
					qualite: "",
					remarque: "",
					role: "",
					tri: "",
					type_de_bois: "",
					assortiment: "",
					produit: "",
					type_de_produit: "",
					actions: "",
					//only client
					carte: "",
					partage: ""

			},
		//lecteur (transporteur)
		8:
			{
					coupe: "",
					martelage: "",
					cubage: "",
					cubage_tige_pile: "",
					depot: "",
					lot: "R",
					partenaires: "R",
					vente: "",
					utilisateurs: "",
					entreprises: "",
					mon_compte: "RU",
					categorie: "",
					certificats: "R",
					deduction_type: "",
					departement: "",
					essence: "",
					type_de_foret: "",
					martelage_plant: "",
					type_intervention: "",
					tarif: "",
					qualite: "",
					remarque: "",
					role: "",
					tri: "",
					type_de_bois: "",
					assortiment: "",
					produit: "",
					type_de_produit: "",
					actions: "",
					//only client
					carte: "R",
					partage: ""
				},
		//all
		9:
			{
					coupe: "",
					martelage: "",
					cubage: "",
					cubage_tige_pile: "",
					depot: "",
					lot: "R",
					partenaires: "R",
					vente: "",
					utilisateurs: "",
					entreprises: "",
					mon_compte: "RU",
					categorie: "",
					certificats: "R",
					deduction_type: "",
					departement: "",
					essence: "",
					type_de_foret: "",
					martelage_plant: "",
					type_intervention: "",
					tarif: "",
					qualite: "",
					remarque: "",
					role: "",
					tri: "",
					type_de_bois: "",
					assortiment: "",
					produit: "",
					type_de_produit: "",
					actions: "",
					//only client
					carte: "",
					partage: ""
				},
};