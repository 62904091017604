import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';

export const MEASUREMENT_METHOD_SUCCESS = 'MEASUREMENT_METHOD_SUCCESS';
export const fetchSuccess = data => ({
    type: MEASUREMENT_METHOD_SUCCESS,
    data
});

export const MEASUREMENT_METHOD_ERROR = 'MEASUREMENT_METHOD_ERROR';
export const fetchError = error => ({
    type: MEASUREMENT_METHOD_ERROR,
    error
});

export const postMeasurementPileMethod = (measurement_method) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/measurement_pile_method`, measurement_method)
        .then(({MEASUREMENT_METHOD}) => dispatch(fetchSuccess(MEASUREMENT_METHOD)));
};

export const getMeasurementPileMethods = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/measurement_pile_method/`)
        .then(({measurement_pile_methods}) => dispatch(fetchSuccess(measurement_pile_methods)));
};

export const getMeasurementPileMethod = (id_measurement_pile_method) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/measurement_pile_method/${id_measurement_pile_method}`)
        .then(({MEASUREMENT_METHOD}) => dispatch(fetchSuccess(MEASUREMENT_METHOD)));
};

export const putMeasurementPileMethod = (measurement_method) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/measurement_pile_method/${measurement_method.id_measurement_pile_method}`, measurement_method)
        .then(({MEASUREMENT_METHOD}) => dispatch(fetchSuccess(MEASUREMENT_METHOD)));
};

export const deleteMeasurementPileMethod = (id_measurement_pile_method) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/measurement_pile_method/${id_measurement_pile_method}`)
        .then(({MEASUREMENT_METHOD}) => dispatch(fetchSuccess(MEASUREMENT_METHOD)));
};