import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PRICE_TYPE.GET_ALL:
            return { ...state, all: action.payload };
        case ACTIONS.PRICE_TYPE.SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case ACTIONS.PRICE_TYPE.ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}