/* eslint-disable default-case */
import actions from "redux-form/lib/actions";
import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    data: '',
    error: null,
    all: [],
    searched: {},
    current: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.TOKEN.GET_ALL:
            return {
                ...state,
                all: action.payload,
                searched: []
            }
        case ACTIONS.TOKEN.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.TOKEN.GET:
            return {
                ...state,
                current: action.payload
            };
        case ACTIONS.TOKEN.REQUEST:
            return {
                ...state,
                all: [...state.all, actions.payload]
            };
        default:
            return state;
    }
}