import {ROLES} from "./roles";

export default function isAuthorized(object, action)
{
    if(localStorage.getItem("role"))
    {
        if (object === "*") {
            return true;
        }
        if(ROLES[localStorage.getItem("role")][object] === undefined)
        {
            return false;
        }
        if(object===undefined || object===null || object==="")
        {
            return true;
        }
        return ROLES[localStorage.getItem("role")][object].includes(action)
    }
    else
    {
        return false;
    }
}