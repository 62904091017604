import {API_BASE_URL} from '../config';
import {normalizeResponseErrors} from './utils';
import {saveToken, clearToken} from '../local-storage';
import {setRoleValue} from './role';
import axios from 'axios';
import {axiosGet} from './../axios';
import {axiosPost} from "../axios";

export const SET_TOKEN = 'SET_TOKEN';
export const setToken = token => ({
    type: SET_TOKEN,
    token
});

export const CLEAR_AUTH = 'CLEAR_AUTH';
export const clearAuth = () => ({
    type: CLEAR_AUTH
});

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const authRequest = () => ({
    type: AUTH_REQUEST
});

export const PUBLIC_AUTH_REQUEST = 'PUBLIC_AUTH_REQUEST';
export const publicAuthRequest = () => ({
    type: PUBLIC_AUTH_REQUEST
});

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const authSuccess = app_user => ({
    type: AUTH_SUCCESS,
    app_user
});

export const PUBLIC_AUTH_SUCCESS = 'PUBLIC_AUTH_SUCCESS';
export const publicAuthSuccess = () => ({
    type: PUBLIC_AUTH_SUCCESS,
    app_user: 'PUBLIC'
});

export const AUTH_ERROR = 'AUTH_ERROR';
export const authError = error => ({
    type: AUTH_ERROR,
    error
});

// Stores the auth token in state and localStorage
// Stores the user data stored with the token
const storeAuthInfo = (token, app_user, dispatch) => {
    app_user.companies.map((company)=>{
        if(company.id_company == localStorage.getItem("company"))
        {
            localStorage.setItem("role", company.has_company.id_role);
        }
    })

    dispatch(setToken(token));
    dispatch(authSuccess(app_user));
    saveToken(token, app_user.id_user);
    getLoggedUserRoles(app_user, token, dispatch);

};


const storeRolesInfo = (roles, dispatch) => {
    // dispatch(setRoleValue(maxRole));
}

const getLoggedUserRoles = (app_user, token, dispatch) => {
    axiosGet(`${API_BASE_URL}/users/${app_user.id_user}/roles`).then((res)=>{
        let roles =[];
        roles.push(res.data);
        storeRolesInfo(roles, dispatch)
    }, error=>{
         dispatch(authError(error))
    })


    // return (
    //     fetch(`${API_BASE_URL}/users/${app_user.id_user}/roles`, {
    //         method: 'GET',
    //         headers: {
    //             Authorization: token
    //         }
    //     })
    //         // Reject any requests which don't return a 200 status, creating
    //         // errors which follow a consistent format
    //         .then(res => {
    //             normalizeResponseErrors(res)})
    //         .then(res => {
     //             res.json()}
    //             )
    //         .then(({roles}) => {
    //             storeRolesInfo(roles, dispatch)})
    //         // .catch(err => {
    //         //     dispatch(authError(err));
    //         // })
    // );
}

export const login = (email, password, remember) => dispatch => {

    dispatch(authRequest());
    axiosPost(`${API_BASE_URL}/users/login`, {
        email: email,
        password: password
    }).then((res)=>{
        if(!localStorage.getItem('company'))
        {
            if (res.data.app_user.companies.length > 0) {
                localStorage.setItem('company', res.data.app_user.companies[0].id_company);
            } else {
                return alert("Vous n'êtes dans aucune entreprise.");
            }
            localStorage.setItem('user_id', res.data.app_user.id_user);
        }

        if (remember) {
            localStorage.setItem('remember', 'true');
            localStorage.setItem('email', email);
            localStorage.setItem('password', password);
        } else {
            localStorage.removeItem('remember');
            localStorage.removeItem('email');
            localStorage.removeItem('password');
        }

        storeAuthInfo(res.data.token, res.data.app_user, dispatch)
        window.location.reload();

    }, error=>{
        dispatch(authError(error))
    });


    // dispatch(authRequest());
    // return (
    //     fetch(`${API_BASE_URL}/users/login`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             email,
    //             password
    //         })
    //     })
    //         // Reject any requests which don't return a 200 status, creating
    //         // errors which follow a consistent format
    //         .then(res => normalizeResponseErrors(res))
    //         .then(res => res.json())
    //         .then(({token, app_user}) => storeAuthInfo(token, app_user, dispatch))
    //         .catch(err => {
    //             dispatch(authError(err));
    //         })
    // );
};

export const publicLogin = (token) => dispatch => {
    dispatch(publicAuthRequest());
    axiosPost(`${API_BASE_URL}/tokens/auth`, {
        token: token
    }).then((res) => {
        if(res.status === 200){
            saveToken("Public " + token, "PUBLIC");
            dispatch(publicAuthSuccess());
        }
    }, error=>{
        return;
        dispatch(authError(error))
    });
    
}

export const refreshToken = () => (dispatch, getState) => {

    dispatch(authRequest());
    const token = getState().auth.token;
    axios.post(`${API_BASE_URL}/users/refresh`,{},{headers:{Authorization: token}}).then((res)=>{
        storeAuthInfo(res.data.token, res.data.app_user, dispatch)
    }, error=>{
        dispatch(authError(error))
    });
    //
    // dispatch(authRequest());
    // const token = getState().auth.token;
    // return fetch(`${API_BASE_URL}/users/refresh`, {
    //     method: 'POST',
    //     headers: {
    //         // Provide our existing token as credentials to get a new one
    //         Authorization: token
    //     }
    // })
    //     .then(res => normalizeResponseErrors(res))
    //     .then(res => res.json())
    //     .then(({token, app_user}) => storeAuthInfo(token, app_user, dispatch))
    //     .catch(err => {
    //         // We couldn't get a refresh token because our current credentials
    //         // are invalid or expired, or something else went wrong, so clear
    //         // them and sign us out
    //         dispatch(authError(err));
    //         dispatch(clearAuth());
    //         clearToken(token);
    //     });
};