import {WOOD_TYPE_SUCCESS, WOOD_TYPE_ERROR} from '../actions/wood_type';

const initialState = {
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === WOOD_TYPE_SUCCESS) {
        return {
            ...state,
            data: action.data,
            error: null
        };
    } else if (action.type === WOOD_TYPE_ERROR) {
        return {
            ...state,
            error: action.error
        };
    }
    return state;
}