import {API_BASE_URL} from '../config';
import {ACTIONS} from './_actions_types';
import axios from 'axios';
import {axiosGet, axiosPost} from './../axios';
import {axiosDelete, axiosPut} from "../axios";
import {displayErrorToast, displaySuccessToast} from "./toast";


export const getPartners = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/partners`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.GET_ALL,
            payload:res.data.partners
        })
    });
};

export const getPartner = (id) => (dispatch) => {
    axiosGet(`${API_BASE_URL}/partners/${id}`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.GET,
            payload:res.data.partner
        })
    });
};

export const postPartner = (partner) => (dispatch) => {
    axiosPost(`${API_BASE_URL}/partners`, partner).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.POST,
            payload:res.data
        })
        displaySuccessToast('Partenaire créer avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la création du partenaire`);
    });
};

export const searchPartners = (data) => (dispatch) => {
    dispatch({
        type:ACTIONS.PARTNER.SEARCH,
        payload:{data}
    })

};
export const deletePartner = (id) => (dispatch) => {
    axiosDelete(`${API_BASE_URL}/partners/`+id).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.DELETE,
            payload:{id:id,partner:res.data}
        })
        displaySuccessToast('Partenaire supprimé avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la suppression du partenaire`);
    });
};


export const putPartner = (partner) => (dispatch) => {
    axiosPut(`${API_BASE_URL}/partners/${partner.id_partner}`, partner).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.PUT,
            payload:{partner:res.data}
        })
        displaySuccessToast('Partenaire modifié avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la modification du partenaire`);
    });
};

export const getCertifiedPartners = (id_company) => (dispatch) => {
    axiosGet(`${API_BASE_URL}/companies/${id_company}/certified_partners`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.GET_CERTIFIED,
            payload:res
        })
    });
};

export const getPartnerCertificates = (id_partner) => (dispatch) => {
    axiosGet(`${API_BASE_URL}/partners/${id_partner}/certificates`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.GET_PARTNER_CERTIFICATES,
            payload:res
        })
    });
};

export const getPartnerDivisions = (id_partner) => (dispatch) => {
    axiosGet(`${API_BASE_URL}/partners/${id_partner}/divisions`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.GET_PARTNER_DIVISIONS,
            payload:res
        })
    });
};

export const getPartnerCertificatesAsync = (id_partner) => (dispatch) => new Promise((resolve, reject) => {
    axiosGet(`${API_BASE_URL}/partners/${id_partner}/certificates`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.GET_PARTNER_CERTIFICATES,
            payload:res
        });
        resolve();
    });
});

export const postPartnerCertificate = (data) => (dispatch) => {
    axiosPost(`${API_BASE_URL}/partners/${data.id_partner}/certificates/${data.id_certificate}`, data).then((res)=>{
        dispatch(getPartnerCertificates(data.id_partner))
    });
};

export const postPartnerDivision = (data) => (dispatch) => {
    axiosPost(`${API_BASE_URL}/partners/${data.id_partner}/divisions`, data).then((res)=>{
        dispatch(getPartnerDivisions(data.id_partner))
    });
};

export const putPartnerCertificate = (id_certificate, data) => (dispatch) => {
    return axiosPut(`${API_BASE_URL}/partners/${data.id_partner}/certificates/${id_certificate}`, data).then((res)=>{
        dispatch(getPartnerCertificates(data.id_partner))
    });
};

export const putPartnerDivision = (id_division, data) => (dispatch) => {
    return axiosPut(`${API_BASE_URL}/partners/${data.id_partner}/divisions/${id_division}`, data).then((res)=>{
        dispatch(getPartnerDivisions(data.id_partner))
    });
};

export const deletePartnerCertificate = (id_certificate, id_partner) => (dispatch) => {
    return axiosDelete(`${API_BASE_URL}/partners/${id_partner}/certificates/${id_certificate}`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.DELETE_PARTNER_CERTIFICATES,
            payload:{id:id_certificate,certificate:res.data}
        })
    });
};

export const deletePartnerDivision = (id_division, id_partner) => (dispatch) => {
    return axiosDelete(`${API_BASE_URL}/partners/${id_partner}/divisions/${id_division}`).then((res)=>{
        dispatch({
            type:ACTIONS.PARTNER.DELETE_PARTNER_DIVISIONS,
            payload:{id:id_division,division:res.data}
        })
    });
};