import {MOTIVE_SUCCESS, MOTIVE_ERROR} from '../actions/motive';

const initialState = {
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === MOTIVE_SUCCESS) {
        return {
            ...state,
            data: action.data,
            error: null
        };
    } else if (action.type === MOTIVE_ERROR) {
        return {
            ...state,
            error: action.error
        };
    }
    return state;
}