export const loadToken = () => {
    return localStorage.getItem('token');
};

export const saveToken = (authToken,user_id) => {
    try {
        localStorage.setItem('token', authToken);
        localStorage.setItem('user_id', user_id);
    } catch (e) {
        alert(e);
    }
};

export const clearToken = () => {
    try {
        localStorage.removeItem('company');
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
    } catch (e) {}
};