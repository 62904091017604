export const SET_COMPANY = 'SET_COMPANY';
export const setCompany = id_company => ({
    type: SET_COMPANY,
    id_company
});
export const CUBAGE_FILL_LOT = 'CUBAGE_FILL_LOT';
export const cubageFillLot = id_cubage_fill_lot => ({
    type: CUBAGE_FILL_LOT,
    id_cubage_fill_lot
});

export const NEXT_ACTION = 'NEXT_ACTION';
export const setNextAction = next_action => ({
    type: NEXT_ACTION,
    next_action
});