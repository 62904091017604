import {QUALITY_SUCCESS, QUALITY_ERROR} from '../actions/quality_usage';

const initialState = {
    all: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === QUALITY_SUCCESS) {
        return {
            ...state,
            data: action.data,
            error: null
        };
    } else if (action.type === QUALITY_ERROR) {
        return {
            ...state,
            error: action.error
        };
    }
    return state;
}
