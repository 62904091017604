import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: {},
    current: {},
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PRODUCT_TYPE.GET_ALL:
            return {
                ...state,
                all: action.payload.product_types
            };
        case ACTIONS.PRODUCT_TYPE.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.PRODUCT_TYPE.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(product_type => product_type.id_product_type != action.payload.action.id_product_type),
                    action.payload.product_type
                ]
            };
        case ACTIONS.PRODUCT_TYPE.DELETE:
            return {
                ...state,
                all: state.all.filter(product_type => product_type.id_product_type != action.payload.id)
            };
        case ACTIONS.PRODUCT_TYPE.GET:
            return {
                ...state,
                current: action.payload
            };
        case ACTIONS.PRODUCT_TYPE.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.ERROR:
            alert(action.payload.data);
            return state;
        default:
            return state;
    }
}