import { ACTIONS } from '../actions/_actions_types';
import { COMPANY_ERROR, COMPANY_SUCCESS } from "../actions/company";

const initialState = {
    all: {},
    current: {},
    searched: {},
    data: '',
    error: null
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.COMPANIES.GET_ALL:
            return { ...state, all: action.payload };
        case ACTIONS.COMPANIES.GET:
            return {
                ...state,
                current: action.payload,
            };
        case ACTIONS.COMPANIES.DELETE:
            return {
                ...state,
                all: state.all.filter(company => company.id_company != action.payload.id)
            };
        case ACTIONS.COMPANIES.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.COMPANIES.POST:
            return {
                ...state,
                all: [...state.all, action.payload]
            }
        case ACTIONS.COMPANIES.PUT:
            return {
                ...state,
                all: [...state.all.filter(company => company.id_company != action.payload.company.company.id_company), action.payload.company.company]
            };
        case COMPANY_SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case COMPANY_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}