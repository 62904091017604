import {API_BASE_URL} from '../config';
import {get, put, del} from './utils';

export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS';
export const fetchSuccess = data => ({
    type: DEPOSIT_SUCCESS,
    data
});

export const DEPOSIT_ERROR = 'DEPOSIT_ERROR';
export const fetchError = error => ({
    type: DEPOSIT_ERROR,
    error
});

export const getDeposits = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/deposits/`)
        .then(({deposits}) => dispatch(fetchSuccess(deposits)));
};

export const getDeposit = (id_deposit) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/deposits/${id_deposit}`)
        .then(({deposit}) => dispatch(fetchSuccess(deposit)));
};

export const putDeposit = (deposit) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/deposits/${deposit.id_deposit}`, deposit)
        .then(({deposit}) => dispatch(fetchSuccess(deposit)));
};

export const deleteDeposit = (id_deposit) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/deposits/${id_deposit}`)
        .then(({deposit}) => dispatch(fetchSuccess(deposit)));
};