import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: [],
    current: {},
    searched: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
        case ACTIONS.LOT_OF_SALE.GET_ALL:
            return {
                ...state,
                all: action.payload,
                searched: [],
            };
        case ACTIONS.LOT_OF_SALE.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.LOT_OF_SALE.PUT:
            return { ...state, all: [...state.all.filter(lot => lot.id_lot_sale !== action.payload.lot_of_sale.id_lot_sale), action.payload.lot] };
        case ACTIONS.LOT_OF_SALE.DELETE:
            return {
                ...state,
                all: state.all.filter(lot => lot.id_lot_sale !== action.payload.id)
            };
        case ACTIONS.LOT_OF_SALE.GET:
            return {
                ...state,
                current: action.payload
            };
        case ACTIONS.LOT_OF_SALE.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.ERROR:
            console.dir(action.payload.data);
            return state;
    }
}
