import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';

export const WOOD_TYPE_SUCCESS = 'WOOD_TYPE_SUCCESS';
export const fetchSuccess = data => ({
    type: WOOD_TYPE_SUCCESS,
    data
});

export const WOOD_TYPE_ERROR = 'WOOD_TYPE_ERROR';
export const fetchError = error => ({
    type: WOOD_TYPE_ERROR,
    error
});

export const postWoodType = (wood_type) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/wood_types`, wood_type)
    .then(({wood_type}) => dispatch(fetchSuccess(wood_type)));
};

export const getWoodTypes = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/wood_types/`)
        .then(({wood_types}) => dispatch(fetchSuccess(wood_types)));
};

export const getWoodType = (id_wood_type) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/wood_types/${id_wood_type}`)
        .then(({wood_type}) => dispatch(fetchSuccess(wood_type)));
};

export const putWoodType = (wood_type) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/wood_types/${wood_type.id_wood_type}`, wood_type)
        .then(({wood_type}) => dispatch(fetchSuccess(wood_type)));
};

export const deleteWoodType = (id_wood_type) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/wood_types/${id_wood_type}`)
        .then(({wood_type}) => dispatch(fetchSuccess(wood_type)));
};