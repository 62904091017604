import {API_BASE_URL} from '../config';
import {get, post, put, del} from './utils';
import {axiosGet} from "../axios";
import {ACTIONS} from "./_actions_types";

export const QUALITY_SUCCESS = 'QUALITY_SUCCESS';
export const fetchSuccess = data => ({
    type: QUALITY_SUCCESS,
    data
});

export const QUALITY_ERROR = 'QUALITY_ERROR';
export const fetchError = error => ({
    type: QUALITY_ERROR,
    error
});

export const postQuality = (quality) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/qualities`, quality)
    .then(({quality}) => dispatch(fetchSuccess(quality)));
};

export const getQualities = () => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/qualities/`)
        .then(({qualities}) => dispatch(fetchSuccess(qualities)))
};

// export const getQualities = () => (dispatch) => {
//     axiosGet(`${API_BASE_URL}/qualities`).then((res)=>{
//         dispatch({
//             type:ACTIONS.QUALITY.GET_ALL,
//             payload:res.data
//         })
//     });
// };

export const getQuality = (id_quality) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/qualities/${id_quality}`)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};

export const putQuality = (quality) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/qualities/${quality.id_quality}`, quality)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};

export const deleteQuality = (id_quality) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/qualities/${id_quality}`)
        .then(({quality}) => dispatch(fetchSuccess(quality)));
};