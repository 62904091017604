import i18next from 'i18next';
import {toast} from "react-toastify";

export const displaySuccessToast = (messageKey) => {
    toast.success(i18next.t(messageKey), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

export const displayErrorToast = (messageKey) => {
    toast.error(i18next.t(messageKey), {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

export const displayErrorToastWithNoTrad = (message) => {
    toast.error(message, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};