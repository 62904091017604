import { ACTIONS } from '../actions/_actions_types';

const initialState = {
    licenses: [],
    addresses: [],
    currentAddresse: {},
    projects: [],
    project_meta: null,
    addresse_meta: null,
    currentProject: {},
    error: null
};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case ACTIONS.FORST_CONTROL.GET_ALL_LICENSES:
            return {
                ...state,
                licenses: action.payload
            };
        case ACTIONS.FORST_CONTROL.GET_ALL_ADDRESSES:
            return {
                ...state,
                addresses: action.payload.addresses,
                addresse_meta: action.payload.meta
            };
        case ACTIONS.FORST_CONTROL.GET_ADDRESS:
            return { ...state, currentAddresse: action.payload };
        case ACTIONS.FORST_CONTROL.GET_ALL_PROJECTS:
            return {
                ...state,
                projects: action.payload.projects,
                project_meta: action.payload.meta
            };
        case ACTIONS.FORST_CONTROL.GET_PROJECT:
            return {
                ...state,
                currentProject: action.payload
            };
        default:
            return state;
    }


}
