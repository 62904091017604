export const USER_COMPANY_SUCCESS = 'USER_COMPANY_SUCCESS';
export const fetchSuccess = data => ({
    type: USER_COMPANY_SUCCESS,
    data
});

export const USER_COMPANY_ERROR = 'USER_COMPANY_ERROR';
export const fetchError = error => ({
    type: USER_COMPANY_ERROR,
    error
});