import { ROLE_SUCCESS, SET_ROLE_VALUE, ROLE_ERROR } from '../actions/role';
import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    data: '',
    value: null,
    error: null,
    all: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.ROLES.GET_ALL:
            return {
                ...state,
                all: action.payload,
            };
        case ROLE_SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case SET_ROLE_VALUE:
            return {
                ...state,
                value: action.value,
                error: null
            };
        case ROLE_ERROR:
            return {
                ...state,
                error: action.error
            };
    }
    return state;
}