import {API_BASE_URL} from '../config';
import {ACTIONS} from './_actions_types';
import {axiosGet, axiosPost, axiosPut, axiosDelete} from './../axios';
import {get, post, put, del} from './utils';
import {fetchSuccess as fetchUserSuccess} from './users';
import {fetchSuccess as fetchOwnerSuccess} from './owner';
import {fetchSuccess as fetchDepositSuccess} from './deposit';
import {fetchSuccess as fetchLotSuccess} from './lot';
import instance from "../axios";
import {displayErrorToast, displaySuccessToast} from "./toast";

export const getCompanies = () => (dispatch) => {

    axiosGet(`${API_BASE_URL}/companies`).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.GET_ALL,
            payload:res.data.companies
        })
    }, err=>{
        console.log(err.message);

        alert(err.message)
    });
};

export const getAllCompanies = () => (dispatch) => {
    axiosGet(`${API_BASE_URL}/allcompanies`).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.GET_ALL,
            payload:res.data.companies
        })
    });
};

export const getCompany = (id) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/companies/${id}`).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.GET,
            payload:res.data.company
        })
    });
};

export const postCompany = (company) => (dispatch) => {
    axiosPost(`${API_BASE_URL}/companies`, company).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.POST,
            payload:res.data
        })
        displaySuccessToast('Entreprise créer avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la création de l'entreprise`);
    });
};

export const deleteCompany = (id) => (dispatch) => {
    axiosDelete(`${API_BASE_URL}/companies/`+id).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.DELETE,
            payload:{id:id,companies:res.data}
        })
        displaySuccessToast('Entreprise suprimée avec succès');
    }, (error)=>{
        dispatch({
            type:ACTIONS.ERROR,
            payload:error.response
        })
        displayErrorToast(`Erreur lors de la suppression de l'entreprise`);
    });
};

export const searchCompany = (data) => (dispatch) => {
    dispatch({
        type:ACTIONS.COMPANIES.SEARCH,
        payload:{data}
    })

};

export const putCompany = (company) => (dispatch) => {
    axiosPut(`${API_BASE_URL}/companies/${company.id_company}`, company).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.PUT,
            payload:{company:res.data}
        })
        displaySuccessToast('Entreprise modifiée avec succès');
    }, (error)=>{
        // dispatch({
        //     type:ACTIONS.ERROR,
        //     payload:error.response
        // })
        displayErrorToast(`Erreur lors de la modification de l'entreprise`);
    });
};


export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const fetchSuccess = data => ({
    type: COMPANY_SUCCESS,
    data
});

export const COMPANY_ERROR = 'COMPANY_ERROR';
export const fetchError = error => ({
    type: COMPANY_ERROR,
    error
});

// Deposit
export const getDeposits = (id_company) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/companies/${id_company}/deposits`)
        .then(({deposits}) => dispatch(fetchDepositSuccess(deposits)));
};

export const postDeposit = (id_company, deposit) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/companies/${id_company}/deposits`, deposit)
        .then(({deposit}) => dispatch(fetchDepositSuccess(deposit)));
};

// Lot
export const getLots = (id_company) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/companies/${id_company}/lots`)
        .then(({lots}) => dispatch(fetchLotSuccess(lots)));
};

export const postLot = (id_company, lot) => (dispatch, getState) => {
    return post(dispatch, getState, `${API_BASE_URL}/companies/${id_company}/lots`, lot)
        .then(({lot}) => dispatch(fetchLotSuccess(lot)));
};


//Share

export const getPublicCompany = (id, token) => (dispatch) => {
    return axiosGet(`${API_BASE_URL}/companies/public/${id}/`).then((res)=>{
        dispatch({
            type:ACTIONS.COMPANIES.GET,
            payload:res.data.company
        });
        return res.data.company
    });
};