import { ACTIONS } from "../actions/_actions_types";

const initialState = {
    all: [],
    searched: {},
    measurements_filter: {},
    measurements: {},
    lot_measurements: {},
    data: '',
    error: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.LOT.GET_ALL:
            return {
                ...state,
                all: action.payload,
                searched: [],   //Vidage de la recherche
            };
        case ACTIONS.LOT.POST:
            return {
                ...state,
                all: [...state.all, action.payload.lot]
            };
        case ACTIONS.LOT.MEASUREMENTS_FILTER:
            return {
                ...state,
                measurements_filter: action.payload
            }
        case ACTIONS.LOT.MEASUREMENTS:
            return {
                ...state,
                measurements: action.payload
            }
        case ACTIONS.LOT.LOT_MEASUREMENTS:
            return {
                ...state,
                lot_measurements: action.payload
            };
        case ACTIONS.LOT.PUT:
            return {
                ...state,
                all: [...state.all.filter(lot => lot.id_lot != action.payload.id_lot), action.payload]
            };
        case ACTIONS.LOT.DELETE:
            return {
                ...state,
                all: state.all.filter(lot => lot.id_lot != action.payload.id)
            }
        case ACTIONS.LOT.GET:
            return {
                ...state,
                all: [...state.all.filter(lot => lot.id_lot != action.payload.id_lot), action.payload]
            };
        case ACTIONS.LOT.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.ERROR:
            alert(action.payload.data);
            return state;
        default:
            return state;
    }
}
