export const OWNER_SUCCESS = 'OWNER_SUCCESS';
export const fetchSuccess = data => ({
    type: OWNER_SUCCESS,
    data
});

export const OWNER_ERROR = 'OWNER_ERROR';
export const fetchError = error => ({
    type: OWNER_ERROR,
    error
});