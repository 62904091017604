import {SubmissionError} from 'redux-form';

// Boilerplate code for handling errors from the API.  If the error response
// contains JSON then we return a rejected promise containing the decoded
// JSON.  If the error doesn't contain JSON then we return a rejected promise
// containing the status text.  If there is no error then we continue with
// the promise chain.
export const normalizeResponseErrors = res => {
    if (!res.ok) {
        if (
            res.headers.has('content-type') &&
            res.headers.get('content-type').startsWith('application/json')
        ) {
            // It's a nice JSON error returned by us, so decode it
            return res.json().then(err => Promise.reject(err));
        }
        // It's a less informative error returned by express
        return Promise.reject({
            code: res.status,
            message: res.statusText
        });
    }
    return res;
};

export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const fetchSuccess = data => ({
    type: FETCH_SUCCESS,
    data
});

export const FETCH_ERROR = 'FETCH_ERROR';
export const fetchError = error => ({
    type: FETCH_ERROR,
    error
});

export const get = (dispatch, getState, url) => {
    const {token} = getState().auth;
    return fetch(url, {
        method: 'GET',
        headers: {
            // Provide our auth token as credentials
            Authorization: token,
            "X-Company":localStorage.getItem('company')
        }
    })
        .then(res => normalizeResponseErrors(res))
        .then(res => res.json())
        .catch(err => handleError(err, dispatch));
};

export const post = (dispatch, getState, url, data) => {
    const {token} = getState().auth;
    return fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            // Provide our auth token as credentials
            Authorization: token,
            "X-Company":localStorage.getItem('company')

        },
        body: JSON.stringify(data)
    })
        .then(res => normalizeResponseErrors(res))
        .then(res => res.json())
        .catch(err => handleError(err, dispatch));
};

export const put = (dispatch, getState, url, data) => {
    const {token} = getState().auth;
    return fetch(url, {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            // Provide our auth token as credentials
            Authorization: token,
            "X-Company":localStorage.getItem('company')

        },
        body: JSON.stringify(data)
    })
        .then(res => normalizeResponseErrors(res))
        .then(res => res.json())
        .catch(err => handleError(err, dispatch));
};

export const del = (dispatch, getState, url) => {
    const {token} = getState().auth;
    return fetch(url, {
        method: 'DELETE',
        headers: {
            // Provide our auth token as credentials
            Authorization: token,
            "X-Company":localStorage.getItem('company')

        }
    })
        .then(res => normalizeResponseErrors(res))
        .then(res => res.json())
        .catch(err => handleError(err, dispatch));
};

const handleError = (err, dispatch) => {
    const {reason, message, location} = err;
            if (reason === 'ValidationError') {
                // Convert ValidationErrors into SubmissionErrors for Redux Form
                return Promise.reject(
                    new SubmissionError({
                        [location]: message
                    })
                );
            } else {
                dispatch(fetchError(err));
            }
}