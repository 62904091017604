import {API_BASE_URL} from '../config';
import {get, put, del} from './utils';

export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';
export const fetchSuccess = data => ({
    type: CATEGORY_SUCCESS,
    data
});

export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const fetchError = error => ({
    type: CATEGORY_ERROR,
    error
});

export const getCategoriesByEssenceAndDepartmentOrPriceType = (id_essence, id_department, id_price_type ) => (dispatch, getState) => {
    const whereClause = (id_price_type !== null) ? `?id_essence=${id_essence}&id_price_type=${id_price_type}` : `?id_essence=${id_essence}&id_department=${id_department}`;
    return get(dispatch, getState, `${API_BASE_URL}/categories${whereClause}`)
        .then(({categories}) => dispatch(fetchSuccess(categories)));
};

export const getCategoriesByEssenceAndDepartmentAndPriceType = (id_essence, id_department, id_price_type ) => (dispatch, getState) => {
    const whereClause = `/${id_essence}/${id_price_type}/${id_department}`;
    return get(dispatch, getState, `${API_BASE_URL}/categories${whereClause}`)
        .then(({categories}) => dispatch(fetchSuccess(categories)));
};

export const getCategories = () => (dispatch, getState) => {
    get(dispatch, getState, `${API_BASE_URL}/categories`)
        .then(({categories}) => {
            dispatch(fetchSuccess(categories))
        });
};

export const getCategory = (id_category) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/categories/${id_category}`)
        .then(({category}) => dispatch(fetchSuccess(category)));
};

export const putCategory = (category) => (dispatch, getState) => {
    return put(dispatch, getState, `${API_BASE_URL}/categories/${category.id_category}`, category)
        .then(({category}) => dispatch(fetchSuccess(category)));
};

export const deleteCategory = (id_category) => (dispatch, getState) => {
    return del(dispatch, getState, `${API_BASE_URL}/categories/${id_category}`)
        .then(({category}) => dispatch(fetchSuccess(category)));
};