// import {HAMMERING_SUCCESS, HAMMERING_ERROR} from '../actions/hammering';
import { ACTIONS } from '../actions/_actions_types';

let initialState = {
    all: {},
    current: {},
    searched: {},
    certificates: {},
    divisions: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.PARTNER.GET_ALL:
            return {
                ...state,
                all: action.payload
            };
        case ACTIONS.PARTNER.POST:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.PARTNER.PUT:
            return {
                ...state,
                all: [
                    ...state.all.filter(partner => partner.id_partner != action.payload.partner.partner.id_partner),
                    action.payload.partner.partner
                ]
            };
        case ACTIONS.PARTNER.DELETE:
            return {
                ...state,
                all: state.all.filter(partner => partner.id_partner != action.payload.id)
            };
        case ACTIONS.PARTNER.GET:
            return {
                ...state,
                current: action.payload
            };
        case ACTIONS.PARTNER.SEARCH:
            return {
                ...state,
                searched: action.payload.data
            };
        case ACTIONS.PARTNER.GET_PARTNER_CERTIFICATES:
            return {
                ...state,
                certificates: action.payload.data.certificates
            };
        case ACTIONS.PARTNER.GET_PARTNER_DIVISIONS:
            return {
                ...state,
                divisions: action.payload.data.divisions
            };
        case ACTIONS.PARTNER.POST_PARTNER_CERTIFICATES:
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.PARTNER.POST_PARTNER_DIVISIONS:
            // console.log("post payload", action.payload)
            return { ...state, all: [...state.all, action.payload] };
        case ACTIONS.PARTNER.PUT_PARTNER_CERTIFICATES:
            return {
                ...state,
                certificates: [
                    ...state.all.filter(certificate => certificate.id_certificate != action.payload.certificate.id_certificate),
                    action.payload.certificate
                ]
            };
        case ACTIONS.PARTNER.PUT_PARTNER_DIVISIONS:
            // console.log("reducer payload", action.payload)
            return {
                ...state,
                divisions: [
                    ...state.all.filter(division => division.id_division != action.payload.division.id_division), action.payload.division,
                    action.payload.division
                ]
            };
        case ACTIONS.PARTNER.DELETE_PARTNER_CERTIFICATES:
            return {
                ...state,
                certificates: state.certificates.filter(certificate => certificate.id_certificate != action.payload.id)
            };
        case ACTIONS.PARTNER.DELETE_PARTNER_DIVISIONS:
            return {
                ...state,
                divisions: state.divisions.filter(division => division.id_division != action.payload.id)
            };
        case ACTIONS.ERROR:
            alert(action.payload.data);
            return state;
        default:
            return state;
    }
}
