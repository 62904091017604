import { ACTIONS } from "../actions/_actions_types";
import { COUNTRY_SUCCESS, COUNTRY_ERROR } from "../actions/country";

const initialState = {
    data: '',
    error: null,
    all: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ACTIONS.COUNTRY.GET_ALL:
            return { ...state, all: action.payload };
        case COUNTRY_SUCCESS:
            return {
                ...state,
                data: action.data,
                error: null
            };
        case COUNTRY_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}