import {SET_TOKEN, CLEAR_AUTH, AUTH_REQUEST, AUTH_SUCCESS, PUBLIC_AUTH_REQUEST, PUBLIC_AUTH_SUCCESS, AUTH_ERROR} from '../actions/auth';

const initialState = {
    token: null, // authToken !== null does not mean it has been validated
    app_user: null,
    loading: false,
    error: null
};

export default function reducer(state = initialState, action) {
    if (action.type === SET_TOKEN) {
        return {
            ...state,
            token: action.token
        };
    } else if (action.type === CLEAR_AUTH) {
        return {
            ...state,
            token: null,
            app_user: null
        };
    } else if (action.type === AUTH_REQUEST) {
        return {
            ...state,
            loading: true,
            error: null
        };
    } else if (action.type === PUBLIC_AUTH_REQUEST){
        return {
            ...state,
            loading: true,
            error: null
        };
    } else if (action.type === PUBLIC_AUTH_SUCCESS){
        return {
            ...state,
            loading: false,
            app_user: 'PUBLIC'
        };
    } else if (action.type === AUTH_SUCCESS) {
        return {
            ...state,
            loading: false,
            app_user: action.app_user
        };
    } else if (action.type === AUTH_ERROR) {
        return {
            ...state,
            loading: false,
            error: action.error
        };
    }
    return state;
}