export const ACTIONS = {
    ACTION: {
        GET_ALL: "ACTION_GET_ALL",
        GET: "GET_ACTION",
        POST: "POST_ACTION",
        PUT: "PUT_ACTION",
        DELETE: "DELETE_ACTION",
        SEARCH: "SEARCH_ACTION",
    },
    HAMMERING: {
        GET_ALL: "HAMMERING_GET_ALL",
        GET: "GET_HAMMERING",
        POST: "POST_HAMMERING",
        PUT: "PUT_HAMMERING",
        DELETE: "DELETE_HAMMERING",
        SEARCH: "SEARCH_HAMMERING",
    },
    PARTNER: {
        GET_ALL: "PARTNER_GET_ALL",
        GET: "GET_PARTNER",
        POST: "POST_PARTNER",
        PUT: "PUT_PARTNER",
        DELETE: "DELETE_PARTNER",
        SEARCH: "SEARCH_PARTNER",
        GET_CERTIFIED: "GET_CERTIFIED",
        GET_PARTNER_CERTIFICATES: "GET_PARTNER_CERTIFICATES",
        PUT_PARTNER_CERTIFICATES: "PUT_PARTNER_CERTIFICATES",
        POST_PARTNER_CERTIFICATES: "POST_PARTNER_CERTIFICATES",
        DELETE_PARTNER_CERTIFICATES: "DELETE_PARTNER_CERTIFICATES",
        GET_PARTNER_DIVISIONS: "GET_PARTNER_DIVISIONS",
        PUT_PARTNER_DIVISIONS: "PUT_PARTNER_DIVISIONS",
        POST_PARTNER_DIVISIONS: "POST_PARTNER_DIVISIONS",
        DELETE_PARTNER_DIVISIONS: "DELETE_PARTNER_DIVISIONS",
    },
    PRICE_TYPE: {
        GET_ALL: "PRICE_TYPE_GET_ALL",
        SUCCESS: "PRICE_TYPE_SUCCESS",
        ERROR: "PRICE_TYPE_ERROR",
    },
    FOREST_TYPE: {
        GET_ALL: "FOREST_TYPE_GET_ALL",
        SUCCESS: "FOREST_TYPE_SUCCESS",
        ERROR: "FOREST_TYPE_ERROR",
    },
    FORST_CONTROL: {
        LOGIN: "LOGIN_FORST_CONTROL",
        GET_ALL_LICENSES: "GET_ALL_FORST_CONTROL_LICENSES",
        GET_ALL_ADDRESSES: "GET_ALL_FORST_CONTROL_ADDRESSES",
        GET_ADDRESS: "GET_FORST_CONTROL_ADDRESS",
        GET_ALL_PROJECTS: "GET_ALL_FORST_CONTROL_PROJECTS",
        GET_PROJECT: "GET_FORST_CONTROL_PROJECT",
    },
    DEPARTMENT: {
        GET_ALL: "DEPARTMENT_GET_ALL",
        SUCCESS: "DEPARTMENT_SUCCESS",
        ERROR: "DEPARTMENT_ERROR",
    },
    COUNTRY: {
        GET_ALL: "COUNTRY_GET_ALL"
    },
    CUT: {
        GET_ALL: "CUT_GET_ALL",
        GET: "GET_CUT",
        POST: "POST_CUT",
        PUT: "PUT_CUT",
        DELETE: "DELETE_CUT",
        SEARCH: "SEARCH_CUT",
    },
    COMPANIES: {
        GET_ALL: "COMPANIES_GET_ALL",
        GET: "GET_COMPANY",
        POST: "POST_COMPANY",
        PUT: "PUT_COMPANY",
        DELETE: "DELETE_COMPANY",
        SEARCH: "SEARCH_COMPANY",
    },
    HAMMERING_STEMS: {
        GET_ALL: "HAMMERING_STEM_GET_ALL",
        CLEAR: "HAMMERING_STEM_CLEAR",
        POST: "HAMMERING_STEM_POST",
        SUCCESS: "HAMMERING_STEM_SUCCESS",
        ERROR: "HAMMERING_STEM_ERROR",
    },
    MEASUREMENTS: {
        GET_ALL: "MEASUREMENT_GET_ALL",
        GET: "GET_MEASUREMENT",
        POST: "POST_MEASUREMENT",
        PUT: "PUT_MEASUREMENT",
        DELETE: "DELETE_MEASUREMENT",
        SEARCH: "SEARCH_MEASUREMENT",
        GET_LAST_NUMBER: "MEASUREMENT_GET_LAST_NUMBER",
        GET_ALL_STEMS: "MEASUREMENT_GET_ALL_STEMS",
        GET_ALL_PILES: "MEASUREMENT_GET_ALL_PILES",
        GET_VOLUME: "MEASUREMENT_GET_VOLUME"
    },
    MEASUREMENT_PILES: {
        GET_MEASUREMENT_PILE_SAMPLES: "MEASUREMENT_GET_MEASUREMENT_PILE_SAMPLES",
        POST_MEASUREMENT_PILE_SAMPLES: "MEASUREMENT_POST_MEASUREMENT_PILE_SAMPLES",
        DELETE_MEASUREMENT_PILE_SAMPLES: "MEASUREMENT_DELETE_MEASUREMENT_PILE_SAMPLES",
        PUT_MEASUREMENT_PILE_SAMPLES: "MEASUREMENT_PUT_MEASUREMENT_PILE_SAMPLES",
        FIND_MEASUREMENT_ASSORTMENT: "MEASUREMENT_FIND_MEASUREMENT_ASSORTMENT",
        MAP_PILES: "MEASUREMENT_PILES_MAP_PILES",
    },
    MEASUREMENT_STEMS: {
        GET_DEDUCTION_BARK: "MEASUREMENT_STEM_GET_DEDUCTION_BARK",
        MAP_STEMS: "MEASUREMENT_STEMS_MAP_STEMS"
    },
    LOT: {
        GET_ALL: "LOT_GET_ALL",
        GET: "GET_LOT",
        POST: "POST_LOT",
        PUT: "PUT_LOT",
        DELETE: "DELETE_LOT",
        SEARCH: "SEARCH_LOT",
        MEASUREMENTS_FILTER: "MEASUREMENTS_FILTER_LOT",
        MEASUREMENTS: "MEASUREMENTS_LOT",
        LOT_MEASUREMENTS: "LOT_MEASUREMENTS_LOT",
        SUCCESS: "LOT_SUCCESS",
        ERROR: "LOT_ERROR",
    },
    LOT_OF_SALE: {
        GET_ALL: "LOT_OF_SALE_GET_ALL",
        GET: "LOT_OF_SALE_GET_LOT",
        POST: "LOT_OF_SALE_POST_LOT",
        PUT: "LOT_OF_SALE_PUT_LOT",
        DELETE: "LOT_OF_SALE_DELETE_LOT",
        SEARCH: "LOT_OF_SALE_SEARCH_LOT",
    },
    USERS: {
        GET_ALL: "USERS_GET_ALL",
        GET_USERS: "USERS_GET_USERS",
        GET: "USERS_GET",
        DELETE: "DELETE_USER",
        SEARCH: "SEARCH_USERS",
        PUT: "PUT_USERS",
        POST: "POST_USERS",

    },
    ROLES: {
        GET_ALL: "ROLES_GET_ALL",
        PUT: "ROLE_PUT"
    },
    QUALITY: {
        GET_ALL: "MEASUREMENT_GET_ALL",
    },
    PRODUCT_TYPE: {
        GET_ALL: "PRODUCT_TYPE_GET_ALL",
        GET: "GET_PRODUCT_TYPE",
        POST: "POST_PRODUCT_TYPE",
        PUT: "PUT_PRODUCT_TYPE",
        DELETE: "DELETE_PRODUCT_TYPE",
        SEARCH: "SEARCH_PRODUCT_TYPE",
    },
    PRODUCT: {
        GET_ALL: "PRODUCT_GET_ALL",
        GET: "GET_PRODUCT",
        POST: "POST_PRODUCT",
        PUT: "PUT_PRODUCT",
        DELETE: "DELETE_PRODUCT",
        SEARCH: "SEARCH_PRODUCT",
    },
    SALE_AND_PURCHASE: {
        GET_ALL: "SALE_AND_PURCHASE_GET_ALL",
        GET: "GET_SALE_AND_PURCHASE",
        POST: "POST_SALE_AND_PURCHASE",
        PUT: "PUT_SALE_AND_PURCHASE",
        DELETE: "DELETE_SALE_AND_PURCHASE",
        SEARCH: "SEARCH_SALE_AND_PURCHASE",
        CEF: "CEF_SALE_AND_PURCHASE",
    },
    TOAST: {
        SUCCESS: "SUCCESS_TOAST",
        ERROR: "ERROR_TOAST",
    },
    QUALITY_USAGE: {
        GET_ALL: "QUALITY_USAGE_GET_ALL",
        GET: "QUALITY_USAGE_GET",
    },
    QUALITY_USAGE_CLASS: {
        GET_ALL: "QUALITY_USAGE_CLASS_GET_ALL",
        GET: "QUALITY_USAGE_CLASS_GET",
    },
    TOKEN: {
        GET_ALL: "TOKEN_GET_ALL",
        GET: "TOKEN_GET",
        SEARCH: "TOKEN_SEARCH",
        REVOKE: "TOKEN_REVOKE",
        REQUEST: "TOKEN_REQUEST"
    },
    ERROR: "ERROR"
};
