import {API_BASE_URL} from '../config';
import {get, put, del, post} from './utils';
import {axiosDelete, axiosPost, axiosPut} from "../axios";
import {getPartnerCertificates} from "./partner";

export const CONDITIONS_GENERALES_ALL = 'CONDITIONS_GENERALES_ALL';
export const fetchAll = data => ({
    type: CONDITIONS_GENERALES_ALL,
    data
});

export const CONDITIONS_GENERALES_SUCCESS = 'CONDITIONS_GENERALES_SUCCESS';
export const fetchSuccess = data => ({
    type: CONDITIONS_GENERALES_SUCCESS,
    data
});

export const CONDITIONS_GENERALES_ERROR = 'CONDITIONS_GENERALES_ERROR';
export const fetchError = error => ({
    type: CONDITIONS_GENERALES_ERROR,
    error
});

export const getAllConditionsGenerales = () => (dispatch, getState) => {
    get(dispatch, getState, `${API_BASE_URL}/conditions_generales`)
        .then(({conditions_generales}) => {
            dispatch(fetchAll(conditions_generales))
        });
};

export const getConditionsGenerales = (id_conditions_generales) => (dispatch, getState) => {
    return get(dispatch, getState, `${API_BASE_URL}/conditions_generales/${id_conditions_generales}`)
        .then(({conditions_generales}) => dispatch(fetchSuccess(conditions_generales)));
};

export const postConditionsGenerales = (conditions_generales) => (dispatch, getState) => {
    return axiosPost(`${API_BASE_URL}/conditions_generales`, conditions_generales).then((res)=>{
        dispatch(getAllConditionsGenerales())
    });
};

export const putConditionsGenerales = (conditions_generales) => (dispatch, getState) => {
    return axiosPut(`${API_BASE_URL}/conditions_generales/${conditions_generales.id_conditions_generales}`, conditions_generales).then((res)=>{
        dispatch(getAllConditionsGenerales())
    });
};

export const deleteConditionsGenerales = (id_conditions_generales) => (dispatch, getState) => {
    return axiosDelete(`${API_BASE_URL}/conditions_generales/${id_conditions_generales}`).then((res)=>{
        dispatch(getAllConditionsGenerales())
    });
};